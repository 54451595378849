import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {EMPTY, filter, map, Observable, of, Subject, switchMap, takeUntil, tap} from 'rxjs';
import { OrganizationService } from '../../core/service/organization.service';
import { selectOrganizationId } from '../../core/store/router/router.selectors';
import { IOpportunity, OpportunityStatus } from '../../shared/model/opportunity.model';
import { IOrganization } from '../../shared/model/organization.model';
import { ISupervisor } from "../../shared/model/supervisor.model";
import { IModalData, ModalService } from "../../shared/components/modal/modal.service";
import {
  NewSupervisorModalComponent
} from "../../shared/components/modal/modal-views/new-supervisor-modal/new-supervisor-modal.component";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SupervisorService } from "../../core/service/supervisor.service";

@Component({
  selector: 'app-organization-config',
  templateUrl: './organization-config.component.html',
  styleUrls: ['./organization-config.component.scss']
})
export class OrganizationConfigComponent implements OnInit, OnDestroy {
  public currentTab: string = 'opportunities';
  public organization!: IOrganization;
  public opportunities: IOpportunity[] = [];
  public activeOpportunities: IOpportunity[] = [];
  public isAdmin: boolean = false;
  public pageType: string = 'supervisor';
  private orgId!: string;

  private endSubscription$ = new Subject();
  public isLeadSupervisor$: Observable<boolean> = new Observable<boolean>();

  constructor(public router: Router,
              private organizationService: OrganizationService,
              private store: Store,
              private modalService: ModalService,
              private supervisorService: SupervisorService) { }

  ngOnInit() {
    this.store.select(selectOrganizationId).pipe(
      filter(Boolean),
      takeUntil(this.endSubscription$),
      switchMap((orgId: string) => {
        this.orgId = orgId;

        return this.organizationService.getOrganization(this.orgId);
      }),
      switchMap((org: IOrganization) => {
        this.organization = org

        return this.organizationService.getOrganizationOpportunities(this.orgId);
      }),
      switchMap((opps: IOpportunity[]) => {
        this.opportunities = opps;
        this.activeOpportunities = opps.filter(opp => opp.status === OpportunityStatus.ACTIVE)
        return EMPTY;
      })
    ).subscribe()

    this.isAdmin = this.router.url.includes('admin');
    if (this.isAdmin) {
      this.pageType = 'admin';
    }

    this.isLeadSupervisor$ = this.supervisorService.getCurrentSupervisor().pipe(map(s => this.organization.supervisorId === s.id))
  }

  ngOnDestroy() {
    this.endSubscription$.next(true);
    this.endSubscription$.complete();
  }

  public isActive(tabName: string): string {
    this.currentTab = this.router.url.includes('opportunities') ? 'opportunities' : 'supervisors';

    return tabName == this.currentTab ? 'active-tab' : '';
  }

  public newSupervisor (newSup: ISupervisor) {
    // TODO: Add logic to add new supervisor to the organization
  }

  public openNewSupervisorModal() {
    const data: IModalData = { opportunities: this.activeOpportunities };
    const newSupervisorModal: NgbModalRef = this.modalService.open(NewSupervisorModalComponent, data)!;

    newSupervisorModal.componentInstance!.newSupervisorEvent.subscribe((supervisor: ISupervisor) => {
      this.newSupervisor(supervisor);
      newSupervisorModal.close();
    });
  }

}
